import React from "react"
import logo from '../images/logo.svg'
import { Link } from "gatsby"
import Calendly from "../components/calendly"
import "../css/style.scss"
import "../css/normalize.css"
import "../css/responsive.scss"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"


const confirmed = () => {


    

    return(

        <Layout>

        <Helmet>
            <title>Confirmed</title>
        </Helmet>

        

        <div className="callPage-body-container">

            {/*
            <section className="callPage-nav">
                <div className="logo"><img src={logo} alt="logo"/></div>
            </section>
            */ }

            <div className="callPage-titles">
                <h1>We've received your trial request</h1>
                <p>Thanks for requesting a trial, we'll be touch to get you setup shortly.</p>
            </div>

            <div className="callConfirmed">

            </div>


       
           

        

        </div>
        
    </Layout>



    )
}

export default confirmed
